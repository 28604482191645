import React, { useState } from 'react';
import pageinationStyle from '../mla-by-community/mla-by-community.module.css';
import style from './committee-reports.module.css';
import { getPaginationData } from '../../../util/util';
import ReactPaginate from 'react-paginate';
import { ReportCard } from './report-card';
import { type CommitteePeriodFile } from '../committee-about/committee-interfaces';

interface ReportPaginationProp {
  reports: CommitteePeriodFile[]
}

/**
 * Pagination for displaying report cards
 * @param props react properties
 */
export function ReportPagination(props: ReportPaginationProp) {
  const [currentPage, setCurrentPage] = useState(0);
  const reports = props.reports;
  const { pageCount, currentItems, handlePageClick } = getPaginationData<CommitteePeriodFile>(reports, setCurrentPage, currentPage);
  return (
    <div className={style.page}>
      {pageCount > 0
        ? <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          className={`pagination ${pageinationStyle.pagination}`}
          previousLabel="< Previous"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
        />
        : <></>
      }
      <div>
        {currentItems.map((report, index) => {
          const cardStyle = index % 2 === 0 ? style.greyColor : style.plainColor;
          const fileId = report?.fileId;
          return (
            <ReportCard report={report} cardStyle={cardStyle} key={fileId} fileBlob={report.fileBlobByFileId}/>
          );
        })}
      </div>
    </div>
  );
}
