import React from 'react';
import style from './committee-file-list.module.css';
import { getParentOrigin } from '../../../util/api';
import { DateTimeConverter } from '../date-time-converter/date-time-converter';
import { type CommitteePeriodFile } from '../../pages/committee-about/committee-interfaces';

export interface CommFileInfo {
  committeePeriodFiles: CommitteePeriodFile[]
  title: string
}

/**
 * @param prop holds committee file links
 * @returns component with list of file links for a committee
 */
export function CommitteeFileList(prop: CommFileInfo) {
  const sortedFileData = sortFilesByDate(prop.committeePeriodFiles);
  return (
    <div>
      {prop.committeePeriodFiles
      ? <div>
        <h2>{prop.title}</h2>
        <div className={style.committeeFileList}>
        {sortedFileData.map((committeePeriodFile) => {
          const file = committeePeriodFile.fileBlobByFileId;
          const fileUrl = `${getParentOrigin()}/committee-content/${file.id}/${file.originalFileName}`;
          const fileDate = committeePeriodFile.date;
          return (
            <div key={committeePeriodFile.fileId}>
              <a href= {fileUrl} target='_parent' className={`${style.title} ${style.section}`}>{file.title}</a>
              {fileDate
                ? <div className={`${style.mark} ${style.box} ${style.section}`}>
                  <span>(</span>
                  <DateTimeConverter dateString={fileDate}></DateTimeConverter>
                  <span>)</span>
                  </div>
                : <></>
              }
            </div>
          );
        })}
        </div>
      </div>
      : <></>}
    </div>
  );
}

/**
 * @param attributes holds committee file array object to be sorted
 * @returns a list of sorted committee file array object
 */
export function sortFilesByDate (attributes: CommitteePeriodFile[]): CommitteePeriodFile[] {
  return attributes.sort((a, b) => {
    const dateA = a.date;
    const dateB = b.date;

    if (dateA && dateB) {
      return new Date(dateB).getTime() - new Date(dateA).getTime();
    } else {
      return 0;
    }
  });
};
