import React from 'react';
import style from './committee-news-card.module.css';
import { getParentOrigin, getAPIBaseUrl } from '../../../util/api';
import { PDFPuck } from '../pdf-puck/pdf-puck';
import type { CommitteeNews } from '../../../interfaces/committee-news';
import { DateTimeConverter } from '../date-time-converter/date-time-converter';

interface NewsEntryProps {
  news: CommitteeNews
  cardStyle: string
}
/**
 * Component that renders a single news entry
 * @param props the news entry object
 */
export function CommitteeNewsEntry (props: NewsEntryProps) {
  const news = props.news;
  const mainUrl = `${getParentOrigin()}/committee-content/${news?.fileBlobByFileId?.id}/${news?.fileBlobByFileId?.originalFileName}`;
  const imageUrl = news.imageId ? (getAPIBaseUrl() + '/pcms/file/' + news.imageId + '/' + (news.fileBlobByImageId?.originalFileName ?? '')) : '';

  return (
    <div className={props.cardStyle}>
      <h3>{news?.fileBlobByFileId?.title}</h3>
      <DateTimeConverter dateString={news.date} />
      {news?.imageId
      ? <img src={imageUrl} alt={news?.fileBlobByFileId?.title ?? ''} />
      : <></>}
      <div className={style.committeeContentNewsLinks}>
        <a href={mainUrl} target='_top'>{news.primaryLinkTitle}</a>

        {news.committeeNewsLinksByCommitteeNewsId.nodes?.map((link, index) => {
          const url = `${getParentOrigin()}${link.linkPath}`;
          return (
            <a key={index} href={url} target='_top'>{link.title}</a>
          )
        })}
      </div>
    </div>
  )
}

export interface CommitteeNewsCardProps {
  date: string | undefined
  title: string | undefined | null
  id: number
  cardStyle: string
  fileName: string | undefined | null
  committeeName: string | undefined | null
}

/**
 * Committee News Card to display Reports, Media and Advertisements
 * @param props react properties
 */
export function CommitteeNewsCard(props: CommitteeNewsCardProps) {
  const { date, title, fileName, committeeName, id } = props;
  const link = `${getParentOrigin()}/committee-content/${id}/${fileName}`;

  return (
    <PDFPuck name={committeeName ?? ''} linkText={title ?? ''} link={link} date={new Date(date ?? '')} sameTab={true} />
  );
}
