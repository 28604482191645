import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { type FileBlob } from '../components/pages/committee-about/committee-interfaces';

export interface RecentDocumentsQuery {
  debates: Debate[]
  parliamentaryDocuments: ParliamentaryDocument[]
  committeeDocuments: CommitteeDocument[]
}

export type CommitteeDocument = FileBlob & {
  committeePeriodFilesByFileId?: {
    nodes: Array<{
      date: string
      committeePeriodByCommitteePeriodId: {
        name: string
      }
    }>
  }
}

export interface AttributesByFileID {
  nodes: SupportingFileAttributesByFileIDNode[]
}

export interface SupportingFileAttributesByFileIDNode {
  committeeSessionByCommitteeSessionId: CommitteeSessionByCommitteeSessionID | null
  committeeParliamentByCommitteeParliamentId: CommitteeSessionByCommitteeSessionID | null
}

export interface CommitteeSessionByCommitteeSessionID {
  name: string
}

export interface Debate {
  id: number
  date: Date
  fileId: number
  timeOfDay: {
    name: 'Morning' | 'Afternoon' | 'Evening' | 'Night' | 'Special'
  }
  debateType: {
    name: 'House' | 'Committee A' | 'Committee C' | 'Committee of the Whole'
  }
  hansardFileAttributeByFileId: HansardFileAttributeByFileID
}

export interface HansardFileAttributeByFileID {
  filePath: string
  fileName: string
  title: string
  id: number
}

export interface ParliamentaryDocument {
  filePath: string
  fileName: string
  date: Date
  title: string | null
  contentTypeId: number
  billAttributesByFileId: BillAttributesByFileID
  votesAttributesByFileId: VotesAttributesByFileID
  ordersAttributesByFileId: OrdersAttributesByFileID
}

export interface OrdersAttributesByFileID {
  nodes: OrdersAttributesByFileIDNode[]
}

export interface OrdersAttributesByFileIDNode {
  number: number
  timesOfDayByTodId: { name: string }
}

export interface VotesAttributesByFileID {
  nodes: VotesAttributesByFileIDNode[]
}

export interface BillAttributesByFileID {
  nodes: BillAttributesByFileIDNode[]
}

export interface BillAttributesByFileIDNode {
  parliamentaryBillByBillId: {
    billNumber: number
    title: string
  }
}

export interface VotesAttributesByFileIDNode {
  voteNumbers: string
}

const useRecentDocuments = (date?: Date | undefined | null) => {
  const [recentDocuments, setRecentDocuments] = useState<RecentDocumentsQuery | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadRecentDocuments = async () => {
      let dateQueryParam = '';
      if (date) {
        dateQueryParam = `?date=${date.toISOString()}`;
      }
      const recentDocuments = await get<RecentDocumentsQuery>(`${getAPIBaseUrl()}/recent-documents${dateQueryParam}`);
      setRecentDocuments(recentDocuments);
      setLoading(false);
    };

    void loadRecentDocuments();
  }, [date]);

  return { recentDocuments, loading };
};

export default useRecentDocuments
