import React from 'react'
import style from '../../pages/committee-about/committee-about.module.css';
import { Loader } from '../../common/loading/loading';
import { useLocation } from 'react-router-dom';
import { getCommitteeParliamentLocation2, getCommitteeSessionLocation } from '../../../util/util';
import { CommitteeNav } from '../../common/committee-nav/committee-nav';
import { CommitteeFileList } from '../../common/commitee-file-list/committee-file-list';
import { CommitteeParliamentSelect } from '../../common/committee-parliament-select/committee-parliament-select';
import { FileType, useCommitteePeriodFiles } from '../../../api/committee-files';
import { type Session } from '../committee-about/committee-interfaces';

interface CommitteeFileProps {
  fileType: FileType
  title: string
  isSession: boolean
}

/**
 * The find Parliamentary Committee Page
 * @param props react properties
 * @returns React Element for the main Parliamentary Committee Page
 */
export function CommitteeMedia(props: CommitteeFileProps) {
  const location = useLocation();
  let session: string | undefined, abbreviation: string, parliament: string;
  if (props.isSession) {
    const locationData = getCommitteeSessionLocation(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
    session = locationData.session;
  } else {
    const locationData = getCommitteeParliamentLocation2(location);
    abbreviation = locationData.commId;
    parliament = locationData.parliament;
  }
  const { committeePeriodFiles: allFiles, loading } = useCommitteePeriodFiles(parliament, abbreviation, props.fileType, session)
  const urlString = `${parliament}parliament-${session ? `${session}session-` : ''}${abbreviation}`;

  const getSessionURL = (parliament: string, abbreviation: string, session?: Session | undefined) => {
    return `parliamentary-business/committees/${parliament}-${session ? `${session.number}${session.annotation}session-` : ''}${abbreviation}`;
  };

  const activeIndex = props.fileType === FileType.Report ? 5
    : props.fileType === FileType.Media ? 6
    : props.fileType === FileType.Adverisement ? 7 : 1;
  const capitalizedType = props.fileType.charAt(0).toUpperCase() + props.fileType.slice(1);
  const breadcrumbs = [
    {
      name: 'shortName',
      order: 2
    },
    {
      name: capitalizedType
    }]
  return (
    <Loader loading={loading} notFound={!allFiles?.length}>
      <h2>{parliament} Parliament{session ? `, ${session} Session` : ''}</h2>
      <div>
        <CommitteeParliamentSelect abbreviation={abbreviation} getSessionURL={getSessionURL} currentSession={session} currentParliament={parliament}/>
        <div className={style.committeeAboutContent}>
          <CommitteeNav parliament={parliament} session={session} abbreviation={abbreviation} urlAppendix={urlString} activeIndex={activeIndex} breadcrumbs={breadcrumbs}/>
          <div className={style.info}>
            {allFiles
              ? <CommitteeFileList committeePeriodFiles={allFiles} title={props.title} ></CommitteeFileList>
              : <></>
            }
          </div>
        </div>
      </div>
    </Loader>
  )
}
