import React, { useEffect, useState } from 'react';
import style from './recent-documents.module.css';
import { useSearchParams } from 'react-router-dom';
import { get } from '../../../api/api';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { PDFPuck } from '../pdf-puck/pdf-puck';
import { Loader } from '../loading/loading';
import { type FileBlob } from '../../pages/committee-about/committee-interfaces';

interface RecentDocumentsQuery {
  reports: RecentDocument[]
  media: RecentDocument[]
  adverts: RecentDocument[]
}

type RecentDocument = FileBlob & {
  committeePeriodFilesByFileId?: {
    nodes: Array<{
      date: string
      committeePeriodByCommitteePeriodId: {
        name: string
      }
    }>
  }
}

/**
 * The recent documents widget
 */
export function RecentDocuments() {
  const [searchParams] = useSearchParams();
  let [reportsNum, mediaNum, advertsNum] = [
    parseInt(searchParams.get('reports') ?? '0'),
    parseInt(searchParams.get('media') ?? '0'),
    parseInt(searchParams.get('adverts') ?? '0')
  ];
  [reportsNum, mediaNum, advertsNum] = [
    isNaN(reportsNum) ? 0 : reportsNum,
    isNaN(mediaNum) ? 0 : mediaNum,
    isNaN(advertsNum) ? 0 : advertsNum
  ];

  const [documents, setDocuments] = useState<RecentDocumentsQuery>();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const loadDocuments = async () => {
      const params = `reports=${reportsNum}&media=${mediaNum}&adverts=${advertsNum}`
      const response = await get<RecentDocumentsQuery>(`${getAPIBaseUrl()}/pcms/parliamentary-business/recent-documents?${params}`);

      setDocuments(response);
      setLoading(false);
    }
    void loadDocuments();
  }, [reportsNum, mediaNum, advertsNum]);

  return (
    <Loader
      loading={loading}
      notFound={!documents?.reports?.length && !documents?.media?.length && !documents?.adverts?.length}
    >
      <div className={style.recentDocuments}>
        <h3>Recent Documents</h3>
        {documents?.reports && documents.reports.length > 0
        ? <DocumentsContainer documents={documents.reports} title="Committee Reports"/>
        : null}
        {documents?.media && documents.media.length > 0
        ? <DocumentsContainer documents={documents.media} title="Media Releases"/>
        : null}
        {documents?.adverts && documents.adverts.length > 0
        ? <DocumentsContainer documents={documents.adverts} title="Advertisements"/>
        : null}
      </div>
    </Loader>
  );
}

interface DocumentsContainerProps {
  documents: RecentDocument[]
  title: string
}

/**
 * A container for the documents
 * @param props react properties
 */
function DocumentsContainer(props: DocumentsContainerProps) {
  return (
    <div>
      <h2>{props.title}</h2>
      {props.documents.map((document, index) => {
        const name = document.committeePeriodFilesByFileId?.nodes[0].committeePeriodByCommitteePeriodId.name ?? '';
        const date = document.committeePeriodFilesByFileId?.nodes[0].date ?? '';
        const link = `${getParentOrigin()}/committee-content/${document.id}/${document.originalFileName}`;

        return <PDFPuck key={index} name={name} linkText={document.title ?? ''} link={link} date={new Date(date)} sameTab />
      })}
    </div>
  );
}
