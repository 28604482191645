import { useEffect, useState } from 'react'
import { get } from './api'
import { getAPIBaseUrl } from '../util/api'
import { type ReadingTypeByReadingTypeID } from '../components/pages/bill-reading/bill-reading'

interface BillFileAttributeQuery {
  bills: BillFileAttribute[]
  readingTypes: ReadingTypeByReadingTypeID
}

export interface BillFileAttribute {
  id?: number | null
  billNumber?: number | null
  chapterNumber?: number | null
  billTypeId?: number | null
  memberId?: number | null
  memberAlias?: string | null
  sessionId?: number | null
  title?: string | null
  titleChanged?: boolean | null
  firstReading?: string | null
  secondReading?: string | null
  thirdReading?: string | null
  amendedReading?: string | null
  committeeReading?: string | null
  royalAssent?: string | null
  reportReading?: string | null
  ruledOutOfOrder?: boolean | null
  billAttributesByBillId?: {
    nodes: BillAtrribute[]
  }
}

export interface BillAtrribute {
  id?: number | null
  billId?: number | null
  fileId?: number | null
  readingTypeId?: number | null
  parliamentaryFileAttributeByFileId?: {
    filePath?: string | null
    fileName?: string | null
    date?: string | null
  }
}

const useBillsBySession = (sessionId: number) => {
  const [bills, setBills] = useState<BillFileAttribute[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBills = async () => {
      const response = await get<BillFileAttributeQuery>(`${getAPIBaseUrl()}/pdms/bills/${sessionId}`);
      setBills(response.bills);
      setLoading(false);
    };

    void loadBills();
  }, [sessionId]);

  return { bills, loading };
};

export default useBillsBySession;
