import React, { useState } from 'react';
import pageinationStyle from '../mla-by-community/mla-by-community.module.css';
import style from './committee-calendar.module.css';
import { TranscriptCard } from './transcript-card';
import { getPaginationData, groupBy } from '../../../util/util';
import ReactPaginate from 'react-paginate';
import { type CommitteeTranscript, type AllCommitteeMeetingsNode } from '../../../interfaces/committee-about';
import { type CommitteeTranscriptQuery } from '../../../api/committee-transcripts';
import { CommitteeTranscriptCard } from '../../common/committee-transcript-card/committee-transcript-card';
import { useAllParliaments } from '../../../api/parliament';
import { type Parliament } from '../../../__generated__/graphql';
import { type TranscriptAndAudioNode, type TranscriptAndAudioObj } from '../committee-about/committee-interfaces';

interface PaginationProp {
  data?: TranscriptAndAudioObj
  transcripts?: CommitteeTranscriptQuery
  displayName?: boolean
  baseUrl: string
}

/**
 * Pagination for displaying transcript cards
 * @param props react properties
 */
export function TranscriptPagination(props: PaginationProp) {
  const [currentPage, setCurrentPage] = useState(0);
  const allParliaments = useAllParliaments();
  const allTranscripts = props.data?.nodes ?? [];
  const hansardStatus = props.data?.hStatus;
  const transcripts = props.transcripts;
  const sesOrParId = transcripts?.sesOrParId;
  let pageCount = 0;
  let currentItems: AllCommitteeMeetingsNode[] | TranscriptAndAudioNode[] = [];
  let handlePageClick;
  let hansardFilesGrouped: Map<number, CommitteeTranscript[]>;
  if (transcripts) {
    const { pageCount: count, currentItems: items, handlePageClick: click } = getPaginationData<AllCommitteeMeetingsNode>(transcripts.allMeetings.allCommitteeMeetings.nodes ?? [], setCurrentPage, currentPage);
    pageCount = count;
    currentItems = items;
    handlePageClick = click;
    const transcriptArray = transcripts.committeeTranscripts ?? []
    hansardFilesGrouped = groupBy(transcriptArray, (ct) => ct.committeeTranscriptAttributeByFileId?.committeeMeetingId ?? 0);
  } else if (allTranscripts.length) {
    const { pageCount: count, currentItems: items, handlePageClick: click } = getPaginationData<TranscriptAndAudioNode>(allTranscripts, setCurrentPage, currentPage);
    pageCount = count;
    currentItems = items;
    handlePageClick = click;
  }
  return (
    <div className={style.page}>
      {pageCount > 0
        ? <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          className={`pagination ${pageinationStyle.pagination}`}
          previousLabel="< Previous"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
        />
        : <></>
      }
      <div>
        {currentItems.map((data, index) => {
          const cardStyle = index % 2 === 0 ? style.greyColor : style.plainColor;
          if (((data as TranscriptAndAudioNode).parliamentId ?? (data as TranscriptAndAudioNode).sessionId) && hansardStatus) {
            return (
              <TranscriptCard parliaments={(allParliaments ?? []) as Parliament[]} baseUrl={props.baseUrl} cardObj={data as TranscriptAndAudioNode} statusObj={hansardStatus} cardStyle={cardStyle} displayName={props.displayName} key={index}></TranscriptCard>
            );
          } else if ((data as AllCommitteeMeetingsNode).id) {
            const transcriptNode = data as AllCommitteeMeetingsNode;
            const committeeAbbr = transcriptNode.committeePeriodByCommitteePeriodId?.committeeByCommitteeId.abbreviation;
            const parlOrSessId = sesOrParId ?? transcriptNode.committeePeriodByCommitteePeriodId?.id ?? 0;
            const committeeTitle = transcriptNode.committeePeriodByCommitteePeriodId?.name;
            const meetingId = transcriptNode.id;
            const hansardFiles = hansardFilesGrouped.get(meetingId);
            const toDisplay = hansardFiles?.find((file) => {
              const transcript = file.committeeTranscriptAttributeByFileId;
              return transcript?.bluesStatusByStatusId?.id !== 4;
            })
            return (
              <CommitteeTranscriptCard urlString={props.baseUrl} parOrSessionId={parlOrSessId} meeting={transcriptNode} key={transcriptNode.id} cardStyle={cardStyle} hansardFile={toDisplay} committeeAbbr={committeeAbbr} title={committeeTitle} />
            );
          }
          return (<></>)
        })}
      </div>
    </div>
  );
}
