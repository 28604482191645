import React, { useEffect, useState } from 'react'
import style from '../../pages/committee-about/committee-about.module.css';
import { TableList } from '../../common/table-list/table-list';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { Loader } from '../../common/loading/loading';
import { get } from '../../../api/api';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBuilding, faCalendarDays, faClock, faPencil, faUser } from '@fortawesome/free-solid-svg-icons';
import { CommitteeListSection } from '../../common/committee-list-section/committee-list-section';
import type { CommitteeNewsQuery } from '../../../interfaces/committee-news';
import { CommitteeNewsEntry } from '../../common/committee-news-card/committee-news-card';
import { useCurrentParliament } from '../../../api/parliament';
import { type Parliament } from '../../../__generated__/graphql';

export interface AllCommitteeSessions {
  standing: Committee[]
  special: Committee[]
  statutory?: Committee[]
  currentParliament: ParliamentAndSession
}

export interface Committee {
  committeeByCommitteeId: {
    name: string
    abbreviation: string
    typeId: number
  }
}

export interface CommitteeInfoObject {
  text: string
  url: string
  icon?: IconProp
  active?: boolean
}

export interface ParliamentAndSession {
  parliamentNumber: number
  parliamentAnnotation: string
  sessionNumber: number
  sessionAnnotation: string
}

/**
 * The find Parliamentary Committee Landing Page
 * @returns React Element for the main Parliamentary Committee Landing Page
 */
export function ParliamentaryCommitteeLandingPage() {
  const sessionItemList: CommitteeInfoObject[] = [];
  let standingCommitteeList: CommitteeInfoObject[] = [];
  let specialCommitteeList: CommitteeInfoObject[] = [];
  const statutoryCommitteeList: CommitteeInfoObject[] = [];
  const [committees, setCommittees] = useState<AllCommitteeSessions>();
  const [loading, setLoading] = useState(true);
  const commURL = `${getParentOrigin()}/parliamentary-business/committees`;
  const [committeeNews, setCommitteeNews] = useState<CommitteeNewsQuery>();
  const { currentParliament } = useCurrentParliament();
  useEffect(() => {
    const loadCommittees = async () => {
      const committeesObj = await get<AllCommitteeSessions>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committees`);
      setCommittees(committeesObj);
      setLoading(false);
    };
    const loadNews = async () => {
      const newsObj = await get<CommitteeNewsQuery>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committees-news`);
      setCommitteeNews(newsObj);
    }
    void loadCommittees();
    void loadNews();
  }, []);

  sessionItemList.push({ text: 'About the Committee System', url: `${commURL}/about-the-committee-system`, icon: faBuilding });
  sessionItemList.push({ text: 'Committee Membership', url: `${commURL}/committee-membership`, icon: faUser });
  sessionItemList.push({ text: 'Committee Meetings', url: `${commURL}/committee-meetings`, icon: faCalendarDays });
  sessionItemList.push({ text: 'Committee Reports', url: `${commURL}/committee-reports`, icon: faPencil });
  sessionItemList.push({ text: 'Previous Committees', url: `${commURL}/previous-committees`, icon: faClock });

  if (committees?.standing && currentParliament) {
    standingCommitteeList = prepareObjList(committees.standing, currentParliament);
  }

  if (committees?.special && currentParliament) {
    specialCommitteeList = prepareObjList(committees.special, currentParliament);
  }

  if (committees?.statutory) {
    const parliament = `${currentParliament?.number}${currentParliament?.annotation}parliament`;
    committees.statutory.map((statObj) => {
      const commName = statObj.committeeByCommitteeId.name;
      const commId = statObj.committeeByCommitteeId.abbreviation;
      return statutoryCommitteeList.push({ text: commName, url: `${getParentOrigin()}/parliamentary-business/committees/${parliament}-${commId}` })
    });
  }

  return (
    <Loader loading={loading} notFound={!committees}>
      <div className={style.committeeDescription}>
        Parliamentary committees are appointed by the Legislative Assembly to undertake business on behalf of the Assembly. Committees are made up of small groups of Members who have been appointed by the Legislative Assembly. Committees get their powers from the Legislative Assembly, sometimes referred to as the “House” and must report their findings back to the House. <a href={`${getParentOrigin()}/parliamentary-business/committees/about-the-committee-system`} target='_top'>Read more about Committees »</a>
      </div>
      <div>
        <div className={style.committeeContent}>
            <div className={style.mainNav}>
              <TableList items={sessionItemList} addIcon={true} />
            </div>
            <div className={style.info}>
              <div className={style.committeeDescription}>
              </div>
              {committees?.standing
                ? <CommitteeListSection title="Select Standing Committees" committeeList={standingCommitteeList} />
                : <></>}
              {committees?.special
                ? <CommitteeListSection title="Special Committees" committeeList={specialCommitteeList} />
                : <></>}
              {committees?.statutory
                ? <CommitteeListSection
                  title={`${committees?.statutory.length > 1 ? 'Statutory Committees' : 'Statutory Committee'}`}
                  committeeList={statutoryCommitteeList} />
                : <></>}
            </div>
            <div className={style.committeeContentNews}>
              <h2>Committee News</h2>
              {committeeNews?.allCommitteeNewsEntries?.nodes?.length
              ? <div className={style.committeeContentNewsList}>
                {committeeNews?.allCommitteeNewsEntries?.nodes.map((news, index) => {
                  const cardStyle = index % 2 === 0 ? style.plainColor : style.greyColor;
                  return (
                    <CommitteeNewsEntry key={news.id} news={news} cardStyle={cardStyle} />
                  )
                })}
              </div>
              : <></>}
            </div>
        </div>
      </div>
    </Loader>
  )
}

/**
 * Function that prepares each committee list object
 * @param objList the list of committee passed as an object
 * @param parliament current parliament and session
 */
export function prepareObjList(objList: Committee[], parliament: Parliament) {
  const committeeItemList: CommitteeInfoObject[] = [];
  const parliamentNumber = parliament.number;
  const parliamentAnnotation = parliament.annotation;
  const session = parliament.sessionsByParliamentId?.nodes?.[0];
  const sessionNumber = session?.number;
  const sessionAnnotation = session?.annotation;

  objList.map((obj) => {
    const parliament = `${parliamentNumber}${parliamentAnnotation}parliament`;
    const session = `${sessionNumber}${sessionAnnotation}session`;
    const abbr = obj.committeeByCommitteeId.abbreviation;
    const committeeURL = `${getParentOrigin()}/parliamentary-business/committees/${parliament}-${session}-${abbr}`
    return committeeItemList.push({ text: `${obj.committeeByCommitteeId.name}`, url: committeeURL });
  });

  return committeeItemList;
}
