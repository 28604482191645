import React, { useCallback, useMemo, useState } from 'react';
import style from './progress-of-bills.module.css';
import { gql } from '../../../__generated__';
import { useQuery } from '@apollo/client';
import { groupBy } from '../../../util/util';
import type { Member } from '../../../__generated__/graphql';
import { getParentOrigin } from '../../../util/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { type BillAtrribute, type BillFileAttribute } from '../../../api/bills-by-session';

export const GET_BILLS_MEMBERS = gql(`
  query GetBillMembers($in: [Int!]) {
    allMembers(filter: { id: { in: $in } }) {
      nodes {
        id
        firstName
        lastName
        isHonourable
        isDoctor
      }
    }
  }  
`);

interface ProgressOfBillsTableProps {
  bills: BillFileAttribute[]
  parliamentString: string
  sessionString: string
  type?: 'M' | 'Pr'
}

/**
 * Table of provided bills in progress of bills format
 * @param props ProgressOfBillsTableProps
 * @returns ProgressOfBillsTable component
 */
export function ProgressOfBillsTable(props: ProgressOfBillsTableProps) {
  const { bills, parliamentString, sessionString } = props;
  const billsCopy = [...bills];
  const prefix = props?.type?.length ? `${props.type} ` : '';

  const memberIds = bills
    .map(bill => bill.memberId)
    .filter(memberId => memberId !== null) as number[];

  const { data } = useQuery(GET_BILLS_MEMBERS, { variables: { in: memberIds } });
  const memberMap = useMemo(() =>
    groupBy(data?.allMembers?.nodes ?? [], (member) => member.id)
  , [data]);

  const [sortedBills, setSortedBills] = useState(
    billsCopy.sort((a, b) => {
      if (!a.billNumber || !b.billNumber) {
        return 0;
      }
      return a.billNumber - b.billNumber;
    })
  );

  const formatMember = useCallback((member: Member | null | undefined, memberAlias: string | null | undefined) => {
    const honourable = !props?.type?.length ? 'Hon. ' : '';
    const memberName = `${member?.firstName ?? ''} ${member?.lastName ?? ''}`
    const memberDisplayedName = memberAlias ?? memberName;
    return `${honourable}${memberDisplayedName}`;
  }, []);

  const formatDate = useCallback((dateString: string | null | undefined) => {
    if (!dateString?.length) return '';

    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', timeZone: 'UTC' };
    return date.toLocaleDateString('en-US', options);
  }, []);

  const readingEntry = useCallback((billFiles: BillAtrribute[], readingType: number, date: string | null | undefined) => {
    const readingFiles = billFiles.filter(billFile => billFile?.readingTypeId === readingType);
    const folder = readingTypeToFolder[readingType];
    return readingFiles?.length
      ? readingFiles.map((file, index) => {
        const date = file?.parliamentaryFileAttributeByFileId?.date ?? '';
        const prefix = index > 0 ? 'Amended ' : '';
        const fileName = file?.parliamentaryFileAttributeByFileId?.fileName ?? '';
        const link = `${getParentOrigin()}/parliamentary-business/overview/${parliamentString}-parliament/${sessionString}-session/bills/${folder}/${fileName}`
        return (
          <div key={index}>
            <a href={link} target='_parent'>
              {prefix}{formatDate(date)}
            </a>
          </div>
        );
      }) : formatDate(date);
  }, []);

  // Column number and its currently sorted state
  const [sortedColumn, setSortedColumn] = useState({ column: 0, asc: true });

  const sortByNumber = () => {
    const asc = sortedColumn.column === 0 ? !sortedColumn.asc : true;
    billsCopy.sort((a, b) => {
      if (a.billNumber === null || a.billNumber === undefined) return asc ? -1 : 1;
      if (b.billNumber === null || b.billNumber === undefined) return asc ? 1 : -1;
      return asc ? a.billNumber - b.billNumber : b.billNumber - a.billNumber;
    });
    setSortedBills(billsCopy);
    setSortedColumn({ column: 0, asc });
  };

  const sortByTitle = () => {
    const asc = sortedColumn.column === 1 ? !sortedColumn.asc : true;
    billsCopy.sort((a, b) => {
      const titleA = a.title ?? '';
      const titleB = b.title ?? '';
      if (titleA === '' || titleA === null) return asc ? -1 : 1;
      if (titleB === '' || titleB === null) return asc ? 1 : -1;
      return asc ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });
    setSortedBills(billsCopy);
    setSortedColumn({ column: 1, asc });
  };

  const sortByMember = () => {
    const asc = sortedColumn.column === 2 ? !sortedColumn.asc : true;
    billsCopy.sort((a, b) => {
      const memberA = memberMap.get(a?.memberId ?? 0)?.[0]?.lastName ?? '';
      const memberB = memberMap.get(b?.memberId ?? 0)?.[0]?.lastName ?? '';
      if (memberA === '' || memberA === null) return asc ? -1 : 1;
      if (memberB === '' || memberB === null) return asc ? 1 : -1;
      return asc ? memberA.localeCompare(memberB) : memberB.localeCompare(memberA);
    });
    setSortedBills(billsCopy);
    setSortedColumn({ column: 2, asc });
  };

  const sortByDate = (property: keyof BillFileAttribute, column: number) => {
    const asc = sortedColumn.column === column ? !sortedColumn.asc : true;
    billsCopy.sort((a, b) => {
      const valueA = (a?.[property] ?? '') as string;
      const valueB = (b?.[property] ?? '') as string;
      if (valueA === '' || valueA === null) return asc ? -1 : 1;
      if (valueB === '' || valueB === null) return asc ? 1 : -1;
      const dateA = new Date(valueA);
      const dateB = new Date(valueB);
      return asc ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    });
    setSortedBills(billsCopy);
    setSortedColumn({ column, asc });
  };

  const sortByChapter = () => {
    const asc = sortedColumn.column === 10 ? !sortedColumn.asc : true;
    billsCopy.sort((a, b) => {
      if (a.chapterNumber === null || a.chapterNumber === undefined) return asc ? -1 : 1;
      if (b.chapterNumber === null || b.chapterNumber === undefined) return asc ? 1 : -1;
      return asc ? a.chapterNumber - b.chapterNumber : b.chapterNumber - a.chapterNumber;
    });
    setSortedBills(billsCopy);
    setSortedColumn({ column: 10, asc });
  };

  const titleChanged = sortedBills.some(bill => bill.titleChanged);
  const isRuledOutOfOrder = sortedBills.some(bill => bill.ruledOutOfOrder);

  return (
    <div className={style.pobTable}>
      <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col style={{ width: '6.4%' }} />
          <col style={{ width: '6.4%' }} />
          <col style={{ width: '6.4%' }} />
          <col style={{ width: '6.4%' }} />
          <col style={{ width: '6.4%' }} />
          <col style={{ width: '6.4%' }} />
          <col style={{ width: '6.4%' }} />
          <col style={{ width: '4%' }} />
        </colgroup>
        <thead>
          <tr>
            {[
              { label: 'Bill No.', sortFunc: sortByNumber, column: 0, className: style.billNum },
              { label: 'Title', sortFunc: sortByTitle, column: 1 },
              { label: 'Member', sortFunc: sortByMember, column: 2 },
              { label: 'First Reading', sortFunc: () => { sortByDate('firstReading', 3); }, column: 3 },
              { label: 'Second Reading', sortFunc: () => { sortByDate('secondReading', 4); }, column: 4 },
              { label: 'Committee', sortFunc: () => { sortByDate('committeeReading', 5); }, column: 5 },
              { label: 'Report', sortFunc: () => { sortByDate('reportReading', 6); }, column: 6 },
              { label: 'Amended', sortFunc: () => { sortByDate('amendedReading', 7); }, column: 7 },
              { label: 'Third Reading', sortFunc: () => { sortByDate('thirdReading', 8); }, column: 8 },
              { label: 'Royal Assent', sortFunc: () => { sortByDate('royalAssent', 9); }, column: 9 },
              { label: 'S.B.C. Chap. No.', sortFunc: sortByChapter, column: 10, className: style.chapterNum }
            ].map(({ label, sortFunc, column, className }) => (
              <th
                key={label}
                onClick={sortFunc}
              >
                <div className={`${className ?? ''} ${sortedColumn.column === column ? style.activeColumn : ''}`}>
                  <span>{label}</span>
                  <FontAwesomeIcon icon={sortedColumn.column === column ? (sortedColumn.asc ? faChevronDown : faChevronUp) : faChevronDown} />
                </div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {sortedBills.map((bill, index) => {
            const rowStyle = index % 2 === 0 ? style.altRow : '';
            const member = memberMap.get(bill?.memberId ?? 0)?.[0] as Member | undefined;

            const billFiles = bill?.billAttributesByBillId?.nodes ?? [];
            const titleChanged = bill.titleChanged ? '* ' : '';
            const isRuledOutOfOrder = bill.ruledOutOfOrder ? '† ' : '';
            return (
              <tr key={bill.id} className={rowStyle}>
                <td className={style.billNumRow}>
                  {isRuledOutOfOrder}{titleChanged}{prefix}{bill.billNumber}
                </td>
                <td>{bill.title}</td>
                <td>{formatMember(member, bill.memberAlias)}</td>
                <td>{readingEntry(billFiles, 1, bill?.firstReading)}</td>
                <td>{formatDate(bill?.secondReading)}</td>
                <td>{formatDate(bill?.committeeReading)}</td>
                <td>{formatDate(bill?.reportReading)}</td>
                <td>{readingEntry(billFiles, 2, bill?.amendedReading)}</td>
                <td>{readingEntry(billFiles, 3, bill?.thirdReading)}</td>
                <td>{formatDate(bill?.royalAssent)}</td>
                <td>{bill?.chapterNumber ?? ''}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isRuledOutOfOrder || titleChanged
        ? <div className={style.updateFlag}>
            {isRuledOutOfOrder
              ? <p>† Ruled out of order</p>
              : null}
            {titleChanged
              ? <p>* Title changed.</p>
              : null}
          </div>
        : <></>
      }
    </div>
  );
}

const readingTypeToFolder: Record<number, string> = {
  1: '1st_read',
  2: 'amend',
  3: '3rd_read'
};
