import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { type CommitteePeriodFile } from '../components/pages/committee-about/committee-interfaces';
import { getSessionParams } from '../util/util';

export enum FileType {
  Media = 'media',
  Adverisement = 'advertisements',
  Report = 'reports'
}

export const useCommitteePeriodFiles = (parliament: string, abbreviation: string, type: FileType, session?: string) => {
  const [committeePeriodFiles, setCommitteePeriodFiles] = useState<CommitteePeriodFile[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadCommitteePeriodFiles = async () => {
      const params = getSessionParams(session);
      const committeePeriodFiles = await get<CommitteePeriodFile[]>(`${getAPIBaseUrl()}/pcms/committees/${parliament}/${abbreviation}/${type}`, params);
      setCommitteePeriodFiles(committeePeriodFiles);
      setLoading(false);
    };
    void loadCommitteePeriodFiles();
  }, [parliament, session, abbreviation]);

  return { committeePeriodFiles, loading };
}

export const useCommitteeParliamentFiles = (parliament: string, abbreviation: string, type: FileType) => {
  const [committeeParliamentFiles, setCommitteeParliamentFiles] = useState<CommitteePeriodFile[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadCommitteeSessionFiles = async () => {
      const committeeParliamentFiles = await get<CommitteePeriodFile[]>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committee-parliament/${parliament}/${abbreviation}/${type}`);
      setCommitteeParliamentFiles(committeeParliamentFiles);
      setLoading(false);
    };
    void loadCommitteeSessionFiles();
  }, [parliament, abbreviation]);

  return { committeeParliamentFiles, loading };
};
