import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { getSessionParams } from '../util/util';
import { type MeetingFile } from '../components/pages/committee-about/committee-interfaces';

export enum FileType {
  Media = 'media',
  Adverisement = 'advertisement',
  Report = 'report'
}

interface CommitteeMeetingInfoQuery {
  meetingDocuments: MeetingFile[]
  committeePeriodInformation: {
    adhocDocuments?: string | null | undefined
    individualsDocuments?: string | null | undefined
    organizationsDocuments?: string | null | undefined
  }
}

export const useCommitteePeriodMeetingDocuments = (parliament: string, abbreviation: string, session?: string) => {
  const [committeePeriodMeetingDocuments, setCommitteePeriodMeetingDocuments] = useState<CommitteeMeetingInfoQuery | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadCommitteePeriodMeetingDocuments = async () => {
      const params = getSessionParams(session);
      const committeePeriodMeetingDocuments = await get<CommitteeMeetingInfoQuery>(`${getAPIBaseUrl()}/pcms/committees/${parliament}/${abbreviation}/meetingdocuments`, params);
      setCommitteePeriodMeetingDocuments(committeePeriodMeetingDocuments);
      setLoading(false);
    };
    void loadCommitteePeriodMeetingDocuments();
  }, [parliament, session, abbreviation]);

  return { committeePeriodMeetingDocuments, loading };
}
