import React, { useState } from 'react'
import { useQuery } from '@apollo/client';
import { gql } from '../../../__generated__';
import { Loader } from '../../common/loading/loading';
import { getParentOrigin } from '../../../util/api';
import style from './mla-by-community.module.css';
import ReactPaginate from 'react-paginate';
import { getPaginationData } from '../../../util/util';
import { useCurrentParliament } from '../../../api/parliament';

export const GET_MLAS_BY_COMMUNITY = gql(`
query GetMLAsByCommunity($parliamentId: Int!) {
  allMemberParliaments(
    condition: { parliamentId: $parliamentId, active: true }
    orderBy: ID_DESC
  ) {
    nodes {
      isCounsel
      isDoctor
      isHonourable
      member: memberByMemberId {
        prefix
        firstName
        lastName
      }
      party: partyByPartyId {
        abbreviation
      }
      constituencyId
      constituency: constituencyByConstituencyId {
        id
        name
        community: communitiesByConstituencyId {
          nodes {
            id
            constituencyId
            electoralMapLabel
            electoralMapLink
            title
          }
        }
      }
    }
  }
}
`);

/**
 * groups the communities by their starting letter
 * @param communities communities to group
 * @returns a map of communities by starting letter
 */
function groupCommunities<T>(communities: any) {
  const communityMap = new Map<string, T[]>();
  for (const community of communities) {
    const firstLetter = community?.title.charAt(0) ?? '';
    let key = firstLetter.toUpperCase();
    if (parseInt(firstLetter, 10)) {
      key = '0-9';
    }
    const existingCommunities = communityMap.get(key) ?? [];
    existingCommunities.push(community);
    communityMap.set(key, existingCommunities);
  }
  return communityMap;
}

/**
 * The find mla by constituency page
 * @returns React Element for the main Find mla by constituency
 */
export function MLAByCommunity() {
  const { currentParliament } = useCurrentParliament();
  const { loading, data } = useQuery(GET_MLAS_BY_COMMUNITY,
    { variables: { parliamentId: currentParliament?.id ?? 0 } });

  const communities = data?.allMemberParliaments?.nodes
    ?.flatMap((node) =>
      node?.constituency?.community?.nodes ?? []
    ) ?? [];
  communities.sort((a, b) => a.title.localeCompare(b.title));

  type Community = typeof communities[number];
  const communityMap = groupCommunities<Community>(communities);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentSelectedLetter, setCurrentSelectedLetter] = useState('A');

  const onClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const text = event.currentTarget.textContent ?? 'A';
    setItemOffset(0);
    setCurrentSelectedLetter(text);
  }

  const letters = Array.from('abcdefghijklmnopqrstuvwxyz');
  const currentCommunities = communityMap.get(currentSelectedLetter) ?? [];

  const { pageCount, currentItems, handlePageClick } = getPaginationData<Community>(currentCommunities, setItemOffset, itemOffset);

  const getMemberParliamentByCommunity = (communityId: number) => {
    const memberParliament = data?.allMemberParliaments?.nodes
      ?.find((mp) =>
        mp?.constituency?.community?.nodes
          ?.some((community) => community.id === communityId)
    );
    if (memberParliament?.member) {
      return memberParliament;
    }
    return null;
  };

  return (
    <Loader loading={loading} notFound={!communities?.length}>
      <div className={style.mlaByCommunityContainer}>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          className={`pagination ${style.pagination}`}
          previousLabel="< Previous"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
        />
        <div className={style.mlaCommunityLetters}>
          <span onClick={onClick} className={currentSelectedLetter === '0-9' ? style.selected : style.unselected}>0-9</span>
          {
            letters.map((letter) => (
              <span key={letter} onClick={onClick} className={currentSelectedLetter === letter.toUpperCase() ? style.selected : style.unselected}>
                {letter.toUpperCase()}
              </span>
            ))
          }
        </div>
        <div className={style.mlaByCommunity}>
          <h4>Community</h4>
          <h4>Electoral District</h4>
          <h4>MLA</h4>
          <h4>Party</h4>
          {
            currentItems?.map((community, index) => {
              const memberParliament = getMemberParliamentByCommunity(community?.id ?? 0);
              const member = memberParliament?.member;
              const memberName =
                `${memberParliament?.isDoctor ? 'Dr. ' : ''}${memberParliament?.isHonourable ? 'Hon. ' : ''}\
                ${member?.firstName} ${member?.lastName}${memberParliament?.isCounsel ? ', K.C.' : ''}`
              const parliamentLink = `${currentParliament?.number}${currentParliament?.annotation}-Parliament`
              const mlaPath = encodeURIComponent(`${member?.lastName}-${member?.firstName}`);

              return (
                <React.Fragment key={index}>
                  <div>
                    {community?.title}
                  </div>
                  <div>
                    {memberParliament?.constituency?.name ?? ''}
                  </div>
                  <div>
                    <a
                      href={`${getParentOrigin()}/members/${parliamentLink}/${mlaPath}`}
                      target="_parent"
                    >
                      {memberName}
                    </a>
                  </div>
                  <div>
                    {memberParliament?.party?.abbreviation}
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    </Loader>
  )
}
