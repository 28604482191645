import React, { useEffect, useState } from 'react'
import style from '../committee-calendar/committee-calendar.module.css';
import { getAPIBaseUrl, getParentOrigin } from '../../../util/api';
import { Loader } from '../../common/loading/loading';
import { get } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import { getStatusNameById } from '../committee-calendar/transcript-card';
import { DateTimeConverter } from '../../common/date-time-converter/date-time-converter';
import { TranscriptLinks } from '../../common/transcript-links/transcript-links';
import { StringContentRender } from '../../common/string-content-render/string-content-render';
import { formatBluesDate, formatCommitteeDate, formatTime, sendBreadcrumbToParent } from '../../../util/util';
import { type TranscriptAndAudioObj } from '../committee-about/committee-interfaces';
import { HansardTagRenderer } from '../../common/hansard-tag-renderer/hansard-tag-renderer';

/**
 * The find Parliamentary Committee Calendar Page
 * @returns React Element for list of Parliamentary Committee Transcripts andn Audios
 */
export function CommitteeMeetingDetails() {
  const location = useLocation();
  const pathArray = location.pathname.split('/');

  const [committeeDocuments, setCommitteeDocuments] = useState<TranscriptAndAudioObj>();
  const [loading, setLoading] = useState(true);

  let parliament = pathArray[pathArray.length - 4];
  const abbreviation = pathArray[pathArray.length - 3];
  const meetingId = pathArray[pathArray.length - 1];
  let session = '';
  if (pathArray.length === 8) {
    parliament = pathArray[pathArray.length - 5];
    session = `/${pathArray[pathArray.length - 4]}`
  }

  useEffect(() => {
    const loadCommitteeDocument = async () => {
      const committeeMeetingInfo = await get<TranscriptAndAudioObj>(`${getAPIBaseUrl()}/pcms/committees/${parliament}${session}/${abbreviation}/meetings/${meetingId}`);
      setCommitteeDocuments(committeeMeetingInfo);
      setLoading(false);
    };

    void loadCommitteeDocument();
  }, []);

  const committeeMeetingData = committeeDocuments?.nodes?.[0];
  const committeeMeeting = committeeMeetingData?.meetingInfo;
  const commName = committeeMeetingData?.name;
  const commMeetStartDate = committeeMeetingData?.meetingInfo?.startTime;
  const commMeetEndDate = committeeMeetingData?.meetingInfo?.endTime;
  const meetLocation = committeeMeeting?.location;
  const meetingCity = committeeMeeting?.community;
  const meetDescription = committeeMeeting?.description;
  const statusObj = committeeDocuments?.hStatus;
  const handsardInfoObj = committeeMeetingData?.hansardData?.committeeTranscriptAttributeByFileId;
  const statusName = getStatusNameById(statusObj, handsardInfoObj?.bluesStatusByStatusId?.id) ?? handsardInfoObj?.bluesStatusByStatusId?.name;
  const meetingDocuments = committeeMeetingData?.commDoc;
  const issueNumber = handsardInfoObj?.issueNumber;
  const bluesModified = new Date(handsardInfoObj?.bluesModified + 'Z');
  const bluesModifiedString = formatBluesDate(handsardInfoObj?.bluesModified ?? '');

  const breadcrumbs = [
    {
      name: committeeMeetingData?.shortName === '' ? commName : committeeMeetingData?.shortName ?? '',
      order: 3
    },
    {
      order: 2,
      name: 'Meetings'
    },
    {
      name: `${meetingCity ? meetingCity + ', ' : ''}${formatCommitteeDate(commMeetStartDate ?? '')}`
    }
  ]
  if (committeeMeetingData) {
    for (const breadcrumb of breadcrumbs) {
      if (breadcrumb.name) sendBreadcrumbToParent(breadcrumb.name, breadcrumb.order);
    }
  }
  return (
    <Loader loading={loading} notFound={!committeeMeeting}>
      <div>
        <div>
          <div className={`${style.card} ${style.greyColor}`}>
            {commName
              ? <div className={style.light}>{commName}</div>
              : <></>
            }
            <div className={style.box}>
              {commMeetStartDate
                ? <>
                <DateTimeConverter
                  dateString={commMeetStartDate}
                  divStyle={`${style.meetTime} ${style.largeFont}`}
                />
                {new Date(commMeetStartDate) < new Date()
                ? (<div className={style.mediumFont}>
                    <span>{formatTime(commMeetStartDate)}</span>
                    {'  '}-{'  '}
                    {commMeetEndDate && new Date(commMeetEndDate) < new Date()
                    ? <span>{formatTime(commMeetEndDate)}</span>
                    : 'TBD'}
                  </div>
                ) : null}
                </>
                : <div className={`${style.meetTime} ${style.largeFont}`}>TBD</div>
              }
              {issueNumber
                ? <span className={style.issueNumberFont}>{`Issue No. ${issueNumber}`}</span>
                : <></>
              }
            </div>
            <HansardTagRenderer live={!!handsardInfoObj?.live} statusName={statusName} bluesModified={bluesModified} bluesModifiedString={bluesModifiedString}/>
            {handsardInfoObj
              ? <TranscriptLinks
                hansardData={committeeMeetingData?.hansardData}
                prefix='Minutes/Transcript:'
              ></TranscriptLinks>
              : <></>
            }
          </div>
          <div className={style.meetingDetails}>{meetLocation}
            {meetingCity
              ? <><span>,</span><span className={style.city}> {meetingCity}</span></>
              : <></>
            }
          </div>
          {meetDescription
            ? <StringContentRender
              contentStyle={`${style.details} ${style.stringPadding} ${style.italicStyle}`}
              content={meetDescription}></StringContentRender>
            : <></>
          }
          {meetingDocuments && meetingDocuments.length > 0
            ? <div>
              <div className={style.docTitle}>Meeting Documents</div>
              <div>
                {meetingDocuments.map((doc) => {
                  const fileBlob = doc.fileBlobByFileId;
                  const file = `${fileBlob?.id}/${fileBlob?.originalFileName}`;
                  const docURL = `${getParentOrigin()}/committee-content/${file}`
                  return (
                    <div key={fileBlob?.id} className={style.docLink}>
                      <a href={docURL} target='_parent'>{fileBlob?.title}</a>
                    </div>)
                })}
              </div>
            </div>
            : <></>
          }
        </div>
      </div>
    </Loader>
  )
}
