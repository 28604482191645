import React from 'react'
import { formatCommitteeDate, groupBy } from '../../../util/util';
import { CommitteeNav, type SideNav } from '../../common/committee-nav/committee-nav';
import { Header } from '../../common';
import { getParentOrigin } from '../../../util/api';
import style from './committee-meeting-documents.module.css'
import { StringContentRender } from '../../common/string-content-render/string-content-render';
import { type MeetingFile } from '../../pages/committee-about/committee-interfaces';

interface CommitteeMeetingDocumentProps {
  committeeMeetings: MeetingFile[] | undefined
  navSettings: SideNav
  adhocDocuments?: string | null | undefined
  organizationsDocuments?: string | null | undefined
  individualsDocuments?: string | null | undefined
}

/**
 * The find Parliamentary Committee Page
 * @param props committee meeting props
 * @returns React Element for the main Parliamentary Committee Page
 */
export function CommitteeMeetingDocuments(props: CommitteeMeetingDocumentProps) {
  const meetingDocuments = props.committeeMeetings;
  props.committeeMeetings?.sort((a, b) => {
    const sortA = a.sortOrder ?? 0;
    const sortB = b.sortOrder ?? 0;
    return sortA - sortB;
  });
  let groupedMeetings = new Map<string, MeetingFile[]>();
  if (meetingDocuments) {
    groupedMeetings = groupBy(meetingDocuments, (meetingFile) => meetingFile.committeeMeetingByMeetingId?.startTime ?? '');
  }
  const keys = Array.from(groupedMeetings.keys());
  keys.sort((a, b) => b.localeCompare(a));
  const baseUrl = `${getParentOrigin()}/committee-content`;

  const breadcrumbs = [
    {
      name: 'shortName',
      order: 2
    }]
  return (
    <div className={style.committeeMeetings}>
      <div className={style.sideNavContainer}>
        <CommitteeNav parliament={props.navSettings.parliament} session={props.navSettings.session} abbreviation={props.navSettings.abbreviation} urlAppendix={props.navSettings.urlAppendix} activeIndex={props.navSettings.activeIndex} breadcrumbs={breadcrumbs}></CommitteeNav>
        <div className={style.committeeMeetingContent}>
          {props?.adhocDocuments ? <StringContentRender content={props.adhocDocuments}></StringContentRender> : <></>}

          {(!!props?.organizationsDocuments || !!props?.individualsDocuments)
            ? <div className={style.otherMeetingDocuments}>
              {props?.organizationsDocuments ? <StringContentRender content={props.organizationsDocuments}></StringContentRender> : <></>}
              {props?.individualsDocuments ? <StringContentRender content={props.individualsDocuments}></StringContentRender> : <></>}
            </div>
          : <></>}

          <div>
            {keys.map((key) => {
              const meetings = groupedMeetings.get(key);
              return (
                <div key={key} >
                  <Header variant='thin'>{formatCommitteeDate(key)}</Header>
                  <div className={style.dayMeetings}>
                    <Header size='sm'>Meeting Documents</Header>
                    {meetings?.map((meetingFile) => {
                      return (
                        <div key={meetingFile?.fileBlobByFileId?.id}>
                          <a href={`${baseUrl}/${meetingFile?.fileBlobByFileId?.id}/${meetingFile.fileBlobByFileId?.originalFileName}`} target='_parent'>{meetingFile?.fileBlobByFileId?.title ? meetingFile.fileBlobByFileId.title : meetingFile?.committeeMeetingByMeetingId?.startTime}</a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
