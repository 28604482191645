import React, { useState } from 'react'
import style from './committee-reports.module.css';
import { Loader } from '../../common/loading/loading';
import { CursorPagination } from '../../common/cursor-pagination/cursor-pagination';
import { useCommitteePeriodFileSearch, useCommitteeReports } from '../../../api/committee-reports';
import { ReportPagination } from './report-pagination';
import { SearchBox } from '../../common/search-box/search-box';

/**
 * The find Parliamentary Committee Transcripts and Audios Page
 * @returns React Element for list of Parliamentary Committee Transcripts andn Audios
 */
export function CommitteeReports() {
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const committeeReports = useCommitteeReports();
  const searchedReports = useCommitteePeriodFileSearch(startCursor, endCursor, searchString);
  const formattedSearchResults = searchedReports?.searchFiles?.nodes.map((searchResult) => {
    const firstCommitteePeriodFile = searchResult?.committeePeriodFilesByFileId?.nodes[0];
    return {
      date: firstCommitteePeriodFile.date,
      fileBlobByFileId: {
        title: searchResult.title,
        originalFileName: searchResult.originalFileName,
        id: searchResult.id
      },
      committeePeriodByCommitteePeriodId: {
        name: firstCommitteePeriodFile.committeePeriodByCommitteePeriodId?.name
      }
    }
  });
  const pageInfo = isSearch ? searchedReports?.searchFiles?.pageInfo : committeeReports?.allCommitteePeriodFiles?.pageInfo;
  const reportNodes = isSearch ? formattedSearchResults : committeeReports?.allCommitteePeriodFiles?.nodes;
  const pgStCursor = pageInfo?.startCursor;
  const pgEnCursor = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;

  const handleCursorChange = (startCursor: string | null, endCursor: string | null) => {
    setStartCursor(startCursor);
    setEndCursor(endCursor);
  };

  const handleSearchSubmit = (searchString: string) => {
    setSearchString(searchString);
    setStartCursor(null);
    setEndCursor(null);
    searchString !== '' ? setIsSearch(true) : setIsSearch(false);
  };

  return (
      <div className={style.container}>
        <div className={style.searchCategory}>
          <h3>Search Committee Reports</h3>
          <SearchBox onSubmit={handleSearchSubmit} placeholder='Search...'></SearchBox>
        </div>
        <Loader loading={committeeReports === undefined}>
        <div className={style.reportCategory}>
          <CursorPagination
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
            pgStCursor={pgStCursor}
            pgEnCursor={pgEnCursor}
            handleCursorChange={handleCursorChange}
          />
          {committeeReports !== undefined
            ? <div className={style.reportContent}>
                <ReportPagination reports={reportNodes ?? []} />
              </div>
            : <></>
          }
        </div>
      </Loader>
    </div>
  )
}
