import { useState, useEffect } from 'react';
import { getAPIBaseUrl } from '../util/api';
import { get } from './api';
import { type FileBlob, type CommitteePeriodFile } from '../components/pages/committee-about/committee-interfaces';

interface GetAllCommitteeReportsQuery {
  __typename?: 'Query'
  allCommitteePeriodFiles?: {
    __typename?: 'CommitteePeriodFilesConnection'
    nodes: Array<{
      __typename?: 'CommitteePeriodFile'
      date?: any | null
      fileBlobByFileId: FileBlob
      committeePeriodByCommitteePeriodId?: {
        __typename?: 'CommitteePeriod'
        name?: string | null
      } | null
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: any | null
      startCursor?: any | null
      hasPreviousPage: boolean
      hasNextPage: boolean
    }
  } | null
};

interface SearchFilesQuery {
  __typename?: 'Query'
  searchFiles?: {
      __typename?: 'FileBlobsConnection'
      nodes: Array<({
          __typename?: 'FileBlob'
          committeePeriodFilesByFileId: {
              __typename?: 'CommitteePeriodFilesConnection'
              nodes: Array<{
                  __typename?: 'CommitteePeriodFile'
                  date?: any | null
                  committeePeriodByCommitteePeriodId?: {
                    __typename?: 'CommitteePeriod'
                    name?: string | null
                } | null
              }>
          }
      } & FileBlob)>
      pageInfo: {
          __typename?: 'PageInfo'
          endCursor?: any | null
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor?: any | null
      }
  } | null
}

export const useCommitteeReports = () => {
  const [committeeReports, setCommitteeReports] = useState<GetAllCommitteeReportsQuery>();
  const pcmsUrl = 'pcms/parliamentary-business/committees/committee-reports';

  useEffect(() => {
    const loadCommitteeReports = async () => {
      const committeeReportsObj = await get<GetAllCommitteeReportsQuery>(`${getAPIBaseUrl()}/${pcmsUrl}`) ?? [];
      setCommitteeReports(committeeReportsObj);
    };
    void loadCommitteeReports();
  }, []);
  return committeeReports;
};

/**
 * Custom hook to search for committee period files.
 * @param {string | null} stCursor - The start cursor for pagination.
 * @param {string | null} enCursor - The end cursor for pagination.
 * @param {string} [searchString] - The search string to filter reports.
 * @returns {CommitteePeriodFile[]} - The list of committee period files.
 */
export function useCommitteePeriodFileSearch(stCursor: string | null, enCursor: string | null, searchString?: string) {
  const [searchFilesResult, setSearchFilesResult] = useState<SearchFilesQuery>();
  const pcmsUrl = `search/reports/${searchString}`

  useEffect(() => {
    const loadSearchFilesResult = async () => {
      const searchFilesResult = await get<SearchFilesQuery>(`${getAPIBaseUrl()}/${pcmsUrl}`) ?? [];
      setSearchFilesResult(searchFilesResult);
    };
    void loadSearchFilesResult();
  }, [stCursor, enCursor, searchString]);
  return searchFilesResult;
}
