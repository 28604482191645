import React, { useEffect, useState } from 'react';
import style from '../committee-about/committee-about.module.css';
import { Loader } from '../../common/loading/loading';
import { getAPIBaseUrl } from '../../../util/api';
import type { CommitteeWhatsNewQuery } from '../../../interfaces/committee-news';
import { get } from '../../../api/api';
import { CommitteeNewsCard } from '../../common/committee-news-card/committee-news-card';
import { CommitteeTranscriptCard } from '../../common/committee-transcript-card/committee-transcript-card';
import { groupBy } from '../../../util/util';

/**
 * The Parliamentary Committee Whats New Page
 * @returns React ELement for the Parliamentary Committee Whats New Page
 */
export function ParliamentaryCommitteeNews() {
  const [committeeNews, setCommitteeNews] = useState<CommitteeWhatsNewQuery>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCommitteeNews = async () => {
      const committeeNewsResponse = await get<CommitteeWhatsNewQuery>(`${getAPIBaseUrl()}/pcms/parliamentary-business/committees/committee-whats-new`);
      setCommitteeNews(committeeNewsResponse);
      setLoading(false);
    }
    void loadCommitteeNews();
  }, []);

  const media = committeeNews?.media ?? [];
  const advertisements = committeeNews?.advertisements ?? [];
  const reports = committeeNews?.reports ?? [];
  const meetings = committeeNews?.meetings ?? [];
  const transcripts = committeeNews?.transcripts ?? [];
  const hansardFilesGrouped = groupBy(transcripts, (ct) => ct?.committeeTranscriptAttributeByFileId?.committeeMeetingId ?? 0)

  return (
    <Loader loading={loading} notFound={!committeeNews}>
      <div className={`${style.committeeNews}`}>
        <div className={`${style.committeeNewsColumn}`}>
          <h2>Reports</h2>
          {reports.map((file, index) => {
            const cardStyle = index % 2 === 1 ? style.greyColor : style.plainColor;
            const fileBlob = file.fileBlobByFileId;
            const committeePeriod = file.committeePeriodByCommitteePeriodId;
            return <CommitteeNewsCard id={fileBlob.id} key={fileBlob.id} date={file.date} title={fileBlob.title} fileName={fileBlob.originalFileName} committeeName={committeePeriod?.name} cardStyle={cardStyle} />;
          })}
        </div>

        <div className={`${style.committeeNewsColumn}`}>
          <h2>Media Releases</h2>
          {media.map((file, index) => {
            const cardStyle = index % 2 === 1 ? style.greyColor : style.plainColor;
            const fileBlob = file.fileBlobByFileId;
            const committeePeriod = file.committeePeriodByCommitteePeriodId;
            return <CommitteeNewsCard id={fileBlob.id} key={fileBlob.id} date={file.date} title={fileBlob.title} fileName={fileBlob.originalFileName} committeeName={committeePeriod?.name} cardStyle={cardStyle} />;
          })}

          <h2>Advertisements</h2>
          {advertisements.map((file, index) => {
            const cardStyle = index % 2 === 1 ? style.greyColor : style.plainColor;
            const fileBlob = file.fileBlobByFileId;
            const committeePeriod = file.committeePeriodByCommitteePeriodId;
            return <CommitteeNewsCard id={fileBlob.id} key={fileBlob.id} date={file.date} title={fileBlob.title} fileName={fileBlob.originalFileName} committeeName={committeePeriod?.name} cardStyle={cardStyle} />;
          })}
        </div>

        <div className={`${style.committeeNewsColumn}`}>
          <h2>Recent Meetings</h2>
          {meetings.map((meeting, index) => {
            const parOrSessionId = meeting?.committeeSessionByCommitteeSessionId?.sessionId ??
              meeting.committeeParliamentByCommitteeParliamentId?.parliamentId as number;
            const hansardFiles = hansardFilesGrouped.get(meeting.id);
            const toDisplay = hansardFiles?.find((file) => {
              const transcript = file.committeeTranscriptAttributeByFileId;
              return transcript?.issueNumber ?? transcript?.bluesStatusByStatusId?.id !== 4;
            })
            const cardStyle = (index % 2 === 1 ? style.greyColor : style.plainColor) +
              ' ' + style.committeeNewsTranscript;
            return <CommitteeTranscriptCard urlString={''} parOrSessionId={parOrSessionId} committeeAbbr={meeting.committeeAbbreviation} key={meeting.id} meeting={meeting} hansardFile={toDisplay} cardStyle={cardStyle} title={meeting.committeeName} />;
          })}
        </div>
      </div>
    </Loader>
  );
}
